import React, { useState } from "react";
import axios from 'axios'
import { Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";
// import bg from "../../images/inquiry/bg.png";
const bg = "../../images/inquiry/Inquiry_org.png";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

  const mustClass = 'text-red-500 font-hairline text-xs sm:text-base xl:text-xs mx-2';
  const errorClass = 'text-red-500 font-normal text-xs sm:text-base xl:text-xs';
  const indexTitleClass = 'mt-10  font-normal';
  const formClass = 'sm:text-md xl:text-sm border border-gray-300 rounded-none ';
  const inputClass = formClass + ' form-input w-full lg:w-8/12 xl:w-9/12';
  const selectClass = formClass + ' form-select w-full  lg:w-8/12 xl:w-9/12';
  const textareaClass = formClass + ' form-textarea h-40 w-full';
  const buttonClass = 'w-full xs:w-6/12 md:w-4/12 3xl:w-3/12 mt-10 py-3 md:py-6 xl:py-3 text-center text-white bg-black float-right';

  const [send, setSend] = useState(false);
  const [sending, setSending] = useState(false);
  const [belongName, setBelongName] = useState('企業名');

  const items = {
    name: {
      value: 'お名前',
      key: 'entry.2005620554'
    },
    email: {
      value: 'メールアドレス',
      key: 'entry.1045781291'
    },
    tel: {
      value: '電話番号',
      key: 'entry.1166974658'
    },
    belong: {
      value: '所属先',
      key: 'entry.1065046570'
    },
    inquiry_ahead: {
      value: 'お問い合わせ先',
      key: 'entry.1356559363'
    },
    inquiry: {
      value: 'お問い合わせ内容',
      key: 'entry.839337160'
    }
  }

  function handleAffiliationChange(event) {
    setBelongName('企業名');
    if (event.target.value == 'student') {
      setBelongName('学校名');
    }
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validation = Yup.object().shape({
    name: Yup.string()
      .required(items.name.value + 'は必須です'),
    email: Yup.string()
      .email(items.email.value + 'の形式に誤りがあります')
      .required(items.email.value + 'は必須です'),
    tel: Yup.string()
      .matches(phoneRegExp, items.tel.value + 'の形式に誤りがあります')
      .required(items.tel.value + 'は必須です'),
    belong: Yup.string()
      .required(belongName + 'は必須です'),
    inquiry_ahead: Yup.string()
      .required(items.inquiry_ahead.value + 'は必須です'),
    inquiry: Yup.string()
      .required(items.inquiry.value + 'は必須です'),
  });

  function handleSubmit(values) {
    setSending(true);
    // const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';
    const CORS_PROXY = '';
    const GOOGLE_FORM = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScDZ0P8hbPbSopOCrkN2OiV-iDbAgVpo9ovnvly0UO5t583UA/formResponse';
    const request = new FormData()
    request.append(items.name.key, values.name);
    request.append(items.email.key, values.email);
    request.append(items.tel.key, values.tel);
    request.append(items.belong.key, values.belong);
    request.append(items.inquiry_ahead.key, values.inquiry_ahead);
    request.append(items.inquiry.key, values.inquiry);

    axios.post(CORS_PROXY + GOOGLE_FORM, request,
      {
        withCredentials: true,
        headers: {
          'content-type': 'multipart/form-data',
        }
      })
      .then(() => {
        setSend(true);
        setSending(false);
      })
      .catch(() => {
        // 送信自体は成功するが、CORSによりレスポンスが返ってこないので常にここの処理に入る
        // alert('通信に失敗したので、時間をおいてから再度送信してみてください。');
        setSend(true);
        setSending(false);
      });
  }

  return (
    <Layout>
      <SEO
        description="ALPHA WAVEへのお問い合わせはこちらからお願いします。"
        title="Inquiry"
      />

      <section className={CONST.SECTION_P_AREA_CLASS + " relative"}>
        <StaticImage quality="100" alt="" layout="fullWidth" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.INQUIRY_BACK_GROUND_PATTERN_CLASS} src={bg} />
        <div className="w-full xl:w-5/12 z-10">
          <h1 className={CONST.H1_CLASS + " font-normal"}>お問い合わせ</h1>
          <Formik
            initialValues={{
              name: "",
              email: "",
              tel: "",
              belong: "",
              inquiry_ahead: "",
              inquiry: ""
            }}
            validationSchema={validation}
            onSubmit={values => handleSubmit(values)}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <p className={indexTitleClass}>{items.name.value}<span className={mustClass}>(必須)</span></p>
                <input type="text" name="name" value={props.values.name} onChange={props.handleChange} className={inputClass} />
                <p className={errorClass}>{props.errors.name}</p>

                <p className={indexTitleClass}>{items.email.value}<span className={mustClass}>(必須)</span></p>
                <input type="text" name="email" value={props.values.email} onChange={props.handleChange} className={inputClass} />
                <p className={errorClass}>{props.errors.email}</p>

                <p className={indexTitleClass}>{items.tel.value}<span className={mustClass}>(必須)</span></p>
                <input type="tel" name="tel" value={props.values.tel} onChange={props.handleChange} className={inputClass} />
                <p className={errorClass}>{props.errors.tel}</p>

                <p className={indexTitleClass}>{items.belong.value}<span className={mustClass}>(必須)</span></p>
                <select className={selectClass} onChange={handleAffiliationChange}>
                  <option value="company">企業</option>
                  <option value="student">学生</option>
                </select>

                <p className={indexTitleClass} name="belong_name_text">{belongName}<span className={mustClass}>(必須)</span></p>
                <input type="text" name="belong" value={props.values.belong} onChange={props.handleChange} className={inputClass} />
                <p className={errorClass}>{props.errors.belong}</p>

                <p className={indexTitleClass}>{items.inquiry_ahead.value}<span className={mustClass}>(必須)</span></p>
                <select className={selectClass} name="inquiry_ahead" onChange={props.handleChange}>
                  <option value="未選択" hidden>ー　選択して下さい　ー</option>
                  <option value="会社">会社・事業内容に関するお問い合わせ</option>
                  <option value="採用">インターンシップや採用選考に関するお問い合わせ</option>
                  <option value="教育">教育事業に関するお問い合わせ</option>
                  <option value="その他">その他</option>
                </select>
                <p className={errorClass}>{props.errors.inquiry_ahead}</p>

                <p className={indexTitleClass}>{items.inquiry.value}<span className={mustClass}>(必須)</span></p>
                <textarea type="text" name="inquiry" value={props.values.inquiry} onChange={props.handleChange} className={textareaClass} />
                <p className={errorClass}>{props.errors.inquiry}</p>

                <p className={indexTitleClass}>お問い合わせなどのご質問に必ずしもお答えできない場合がありますので、ご了承ください。</p>
                {!send && <button className={buttonClass + (sending ? ' opacity-50 cursor-not-allowed' : '')} disabled={sending && 'disabled'} type="submit">{sending ? '送信中…' : '　送信　'}</button>}
                {send && <button className={buttonClass + ' cursor-not-allowed'} disabled>送信完了</button>}
              </form>
            )}
          </Formik>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
